import { Gender } from './models/students';
import { IUser } from '../types/auth';

export interface ILoginRequest {
  password: string;
  username: string;
  companyName: string;
}

export interface ICashReportBalanceSummary {
  id: string;
  date: string;
  amount: number;
}
export interface IUpdatePasswordRequest {
  email: string;
  cellNumber: string;
  username: string;
}
export enum UserRole {
  ADMIN = 'admin',
  VENDOR = 'vendor',
}
export enum UserPermission {
  CAN_AUTHORISE = 'CAN_AUTHORISE',
  CAN_DELETE = 'CAN_DELETE',
  CAN_USE_WEB = 'CAN_USE_WEB',
}
export enum CurrencyEnum {
  USD_FC = 'USD_FC',
  EUR_FC = 'EUR_FC',
}
export interface ILoginResponse extends IUser {
  access_token: string;
  tenantId: string;
}
interface SubCategoryItem {
  value_fr: string;
  value_en: string;
  enum_key: string;
  translate_key: string;
}
export interface FeeTypeItemResponse {
  value_fr: string;
  value_en: string;
  enum_key: string;
  generic_key: string;
  accessors: UserRole[];
  translate_key: string;
  sub_category?: SubCategoryItem[] | null;
}
export interface FeeTypeItem {
  value_fr: string;
  value_en: string;
  enum_key: string;
  generic_key: string;
  accessors: UserRole[];
  translate_key: string;
}
export interface IFeeTypesResponse {
  other: FeeTypeItemResponse[];
  student: FeeTypeItemResponse[];
  expenses: FeeTypeItemResponse[];
}
export interface IFeeTypesState {
  other: FeeTypeItem[];
  student: FeeTypeItem[];
  expenses: FeeTypeItem[];
}

export enum StudentStatus {
  IN_DEBT = 'IN_DEBT', // Balance negative and due date reached
  CLEAR = 'CLEAR', //Paid out the entire fee for the registered year
  NOT_REGISTERED = 'NOT_REGISTERED', // Did not pay registration fee but student loaded into the system usually from spreadsheet
  PENDING_REGISTRATION = 'PENDING_REGISTRATION', //Paid registration fee but  pending actual registration ie debit = null or year is not current year
  REGISTERED = 'REGISTERED', // Loaded in the system, paid registration fee  and payment due date not reached yet or reached but student is in order
  CAN_REGISTER = 'CAN_REGISTER', // Loaded in the system, paid registration fee  and payment due date not reached yet or reached but student is in order
}

export interface IStudentSummary {
  firstname: string;
  middleName: string;
  surname: string;
  studentNumber: string;
  gender: Gender;
  email: string;
  classroom: string;
  classroomDisplayName: string;
  credit: number;
  debit: number;
  status: StudentStatus;
  year: string;
  term: string;
  _id: string;
}
export interface IUpdateFeeRequest {
  debit?: number;
  reason: string;
  studentNumber?: string;
}
type Classroom = {
  classFeeCategory: string;
  classroomName: string;
  _id: string;
};
export interface ISchools {
  schoolName: string;
  id: string;
  classrooms: Classroom[];
}

export interface ISuccessResponse {
  success: boolean;
}

export interface IResponseSummary {
  students: number;
  earnings: number;
  weeklyEarnings: ICashReportBalanceSummary[];
  expenses: number;
  balance: number;
  monthlyBalance: ICashReportBalanceSummary[];
}
export interface IUpdateStudentsStatus {
  penalty: number;
  minFee: number;
  status: StudentStatus;
}
export interface IExchangeRateResponse {
  usd_fc: number;
}
