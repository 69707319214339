import { NavItemType } from '../types/menu';
import i18n from '../i18n';
import { Driving, HomeTrendUp, MoneyRecive } from 'iconsax-react';

const icons = {
  dashboard: HomeTrendUp,
  roadmap: Driving,
  exchange: MoneyRecive,
};

const financeMenu: NavItemType = {
  id: 'finance',
  title: i18n.t('nav_section.finance'),
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: i18n.t('sidebar.dashboard'),
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboard,
    },
    {
      id: 'exchangeRate',
      title: i18n.t('sidebar.currency_exchange'),
      type: 'item',
      url: '/exchangeRate',
      icon: icons.exchange,
    },
  ],
};
export default financeMenu;
