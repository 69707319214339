import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import { APP_DEFAULT_PATH, WEB_USER_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { UserPermission, UserRole } from '../../apis/types';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const loginWebUser = () => {
    if (user?.permissions?.includes(UserPermission.CAN_USE_WEB)) {
      navigate(location?.state?.from ? location?.state?.from : WEB_USER_DEFAULT_PATH, {
        state: {
          from: '',
        },
        replace: true,
      });
    }
  };
  const loginAdminUser = () => {
    navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
      state: {
        from: '',
      },
      replace: true,
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (user?.role === UserRole.ADMIN) {
        loginAdminUser();
      } else {
        loginWebUser();
      }
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;
