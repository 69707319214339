import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useGetUserByIdQuery, useLazyGetTransactionForUserQuery } from '../../apis/konektApi';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { IExpensesTransaction, IIncomesTransaction } from '../../apis/models/transactions';
import MainCard from '../../components/MainCard';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IncomeTransactionHistory from './IncomeTransactionHistory';
import ExpenseTransactionHistory from './ExpenseTransactionHistory';
const UserDetailsPage = () => {
  const incomeFeeTypes = useSelector((state: RootState) => {
    return state.feeTypes.other.concat(state.feeTypes.student);
  });
  const expensesFeeTypes = useSelector((state: RootState) => {
    return state.feeTypes.expenses;
  });
  const [getTransactionForUser, userTransactionResult] = useLazyGetTransactionForUserQuery();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));
  const { id } = useParams();
  const { t } = useTranslation();
  const onGetTransactionForUser = async () => {
    if (selectedDate) {
      const date = new Date(selectedDate?.toISOString()).toISOString();
      const userId = id || '';
      await getTransactionForUser({ userId, date });
    }
  };
  const [country, setCountryCode] = useState(40);
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const { data, error, isLoading } = useGetUserByIdQuery({ id: id || '' });
  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <Box>
        <Typography>{t('errors.generic_error')}</Typography>
      </Box>
    );
  }
  let incomesColumnsData: Array<IIncomesTransaction> = [];
  let expensesColumnsData: Array<IExpensesTransaction> = [];
  if (userTransactionResult && userTransactionResult?.data?.incomes.length) {
    incomesColumnsData = userTransactionResult?.data?.incomes.map((row) => {
      const translatedValue = incomeFeeTypes.find((item) => {
        return item && item.enum_key && item.enum_key === row.feeType;
      });
      return {
        ...row,
        feeType: translatedValue?.value_fr || 'Compte non trouvé',
      };
    });
  }
  if (userTransactionResult && userTransactionResult?.data?.expenses.length) {
    expensesColumnsData = userTransactionResult?.data?.expenses.map((row) => {
      const translatedValue = expensesFeeTypes.find((item) => item.enum_key === row.feeCode);
      return { ...row, feeCode: translatedValue?.value_fr || 'Compte non trouvé' };
    });
  }
  const getIncomesTotal = () => {
    let total = 0;
    if (userTransactionResult && userTransactionResult?.data?.incomes.length) {
      total = userTransactionResult?.data?.incomes.reduce((a, b) => a + b.fee_amount, 0);
    }
    return total.toFixed(2);
  };
  const getExpensesTotal = () => {
    let total = 0;
    if (userTransactionResult && userTransactionResult?.data?.expenses.length) {
      total = userTransactionResult?.data?.expenses.reduce((a, b) => a + b.fee_amount, 0);
    }
    return total.toFixed(2);
  };
  return (
    <MainCard title={t('titles.user_details')}>
      {data ? (
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/*<Avatar*/}
                  {/*  src={'/static/images/male.png'}*/}
                  {/*  sx={{*/}
                  {/*    height: 64,*/}
                  {/*    mb: 2,*/}
                  {/*    width: 64,*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <Typography color="textPrimary" gutterBottom variant="h3">
                    {data.names}
                  </Typography>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    {data.role}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {data.email}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {data.cellNumber}
                  </Typography>
                </Box>
                <Box py={1}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item md={7}>
                      <LoadingButton
                        color="primary"
                        disabled={false}
                        fullWidth
                        size="large"
                        variant="contained"
                        loading={userTransactionResult.isLoading}
                        onClick={onGetTransactionForUser}
                      >
                        {t('get_latest_transaction_btn')}
                      </LoadingButton>
                    </Grid>
                    <Grid item md={5}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={selectedDate}
                          onChange={(newValue) => {
                            setSelectedDate(newValue);
                          }}
                          slotProps={{ textField: { variant: 'outlined' } }}
                          // renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>

              <Divider />
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <form autoComplete="off">
              <Card>
                <CardHeader title="Profile" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        helperText="Please specify the surname"
                        label={t('surname_label')}
                        name="surname"
                        disabled
                        value={data.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={t('firstname_label')}
                        name="middleName"
                        disabled
                        value={data.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item md={1} mr={4}>
                          <Select
                            labelId="country-code-label"
                            id="country-code-id"
                            value={country}
                            onChange={handleCountryCodeChange}
                          >
                            <MenuItem value={10}>+353</MenuItem>
                            <MenuItem value={40}>+243</MenuItem>
                            <MenuItem value={20}>+27</MenuItem>
                            <MenuItem value={30}>+44</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item md={9}>
                          <TextField
                            fullWidth
                            helperText="Please specify the surname"
                            label={t('tables.cell_number')}
                            name="cellNumber"
                            disabled
                            value={data.cellNumber}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={t('email_label')}
                        name="email"
                        value={data.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}></Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                  }}
                >
                  <Button type="submit" color="primary" variant="contained">
                    {t('save_btn')}
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
          {userTransactionResult.data && (
            <Grid container>
              {userTransactionResult.data.incomes.length > 0 && (
                <Grid item md={12}>
                  <Typography variant="h3">{t('income_lbl') + getIncomesTotal() + '$'}</Typography>
                  <IncomeTransactionHistory
                    incomesTransactionResult={incomesColumnsData}
                    employee_name={data.names}
                  />
                </Grid>
              )}
              {userTransactionResult.data.expenses.length > 0 && (
                <Grid item md={12}>
                  <Typography variant="h3">
                    {t('expenses_lbl') + getExpensesTotal() + '$'}
                  </Typography>
                  <ExpenseTransactionHistory
                    expenseTransactionResult={expensesColumnsData}
                    employee_name={data.names}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <Box>{t('errors.generic_error')}</Box>
      )}
    </MainCard>
  );
};
export default UserDetailsPage;
