import React from 'react';
import CircularLoader from '../../CircularLoader';
import { useGetClientFeeTypeQuery, useLazyGetFeesMonthlyQuery } from '../../apis/konektApi';
import Home from './home';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
  useGetClientFeeTypeQuery();
  const [getFeesSummary, result] = useLazyGetFeesMonthlyQuery();
  const onDateUpdated = async (dateFrom) => {
    try {
      await getFeesSummary({ dateFrom });
    } catch (e) {}
  };

  if (result.isLoading) {
    return <CircularLoader />;
  } else if (result.error) {
    return <div>{result.error.toString()}</div>;
  }
  return <Home summary={result.data} getSummaryFromDate={onDateUpdated} />;
};

export default DashboardDefault;
