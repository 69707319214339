import { Box, Grid, Stack, Typography } from '@mui/material';
import EcommerceDataCard from '../../components/cards/statistics/EcommerceDataCard';
import { ArrowDown, ArrowUp, Book, Calendar, CloudChange, Wallet3 } from 'iconsax-react';
import EcommerceDataChart from '../../sections/widget/chart/EcommerceDataChart';
import CashReportBalance from '../../sections/widget/chart/CashReportBalance';
import { IResponseSummary } from '../../apis/types';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import WeeklyBalance from '../../sections/widget/data/WeeklyBalance';

interface IHomePageProps {
  summary?: IResponseSummary;

  getSummaryFromDate(dateFrom: string);
}

const Home: React.FC<IHomePageProps> = ({ summary, getSummaryFromDate }) => {
  const theme = useTheme();
  const [date, setDate] = useState(dayjs().startOf('month'));
  const { t } = useTranslation();
  useEffect(() => {
    getSummaryFromDate(date.toISOString());
  }, []);

  if (summary) {
    return (
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {/* row 1 */}
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title={t('dashboard.student_total')}
            count={summary?.students.toString()}
            iconPrimary={<Wallet3 />}
            percentage={
              <Typography color="primary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6%
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.primary.main} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title={t('total_earning')}
            count={summary?.earnings.toFixed(2)}
            color="warning"
            iconPrimary={<Book color={theme.palette.warning.dark} />}
            percentage={
              <Typography
                color="warning.dark"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                <ArrowDown size={16} style={{ transform: 'rotate(-45deg)' }} /> 30.6%
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.warning.dark} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title={t('total_expenses')}
            count={summary?.expenses.toFixed(2)}
            color="success"
            iconPrimary={<Calendar color={theme.palette.success.darker} />}
            percentage={
              <Typography
                color="success.darker"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6%
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.success.darker} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <EcommerceDataCard
            title={t('dashboard.balance')}
            count={summary?.balance.toFixed(2)}
            color="error"
            iconPrimary={<CloudChange color={theme.palette.error.dark} />}
            percentage={
              <Typography
                color="error.dark"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                <ArrowDown size={16} style={{ transform: 'rotate(45deg)' }} /> 30.6%
              </Typography>
            }
          >
            <EcommerceDataChart color={theme.palette.error.dark} />
          </EcommerceDataCard>
        </Grid>

        {/* row 2 */}
        <Grid item xs={12} md={8} lg={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CashReportBalance cashReportSummary={summary.monthlyBalance} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Stack spacing={3}>
            <WeeklyBalance weeklyEarnings={summary.weeklyEarnings} />
          </Stack>
        </Grid>
      </Grid>
    );
  } else
    return (
      <Box>
        <Typography>{t('errors.generic_error')}</Typography>
      </Box>
    );
};

export default Home;
