import { useState, useEffect, FC } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';

// types
import { ThemeMode } from 'types/config';
import { useTranslation } from 'react-i18next';

// chart options
const areaChartOptions = {
  chart: {
    type: 'area',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      type: 'vertical',
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '45%',
      borderRadius: 4,
    },
  },
  grid: {
    strokeDashArray: 4,
  },
};

interface IMonthlyReportChartProps {
  monthlyReport: Array<{
    id: string;
    date: string;
    amount: number;
  }>;
}
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MonthlyReportChart: FC<IMonthlyReportChartProps> = ({ monthlyReport }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const mode = theme.palette.mode;
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<ChartProps>(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        categories: [...monthlyReport.map((item) => MONTHS[new Date(item.date).getMonth()])],
        labels: {
          style: {
            colors: [...monthlyReport.map(() => secondary)],
          },
        },
        axisBorder: {
          show: false,
          color: line,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 11,
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary],
          },
        },
      },
      grid: {
        borderColor: line,
      },
      theme: {
        mode: mode === ThemeMode.DARK ? 'dark' : 'light',
      },
    }));
  }, [mode, primary, secondary, line, theme, monthlyReport]);

  const [series] = useState([
    {
      name: t('titles.balance_details'),
      data: [...monthlyReport.map((item) => Number(item.amount.toFixed(0)))],
    },
  ]);

  return <ReactApexChart options={options} series={series} type="area" height={260} />;
};

export default MonthlyReportChart;
