export interface IStudentsFetchAllRequest {
  limit: number;
  page: number;
}

export interface IStudentsFetchAllByLevelRequest {
  level: YearlyFeeType;
  pattern: string;
  term: string;
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
}

export enum StudentStatus {
  IN_DEBT = 'IN_DEBT', // Balance negative and due date reached
  CLEAR = 'CLEAR', //Paid out the entire fee for the registered year
  NOT_REGISTERED = 'NOT_REGISTERED', // Did not pay registration fee but student loaded into the system usually from spreadsheet
  PENDING_REGISTRATION = 'PENDING_REGISTRATION', //Paid registration fee but  pending actual registration ie debit = null or year is not current year
  REGISTERED = 'REGISTERED', // Loaded in the system, paid registration fee  and payment due date not reached yet or reached but student is in order
  CAN_REGISTER = 'CAN_REGISTER', // Loaded in the system, paid registration fee  and payment due date not reached yet or reached but student is in order
}

export interface IStudentTableView {
  names: string;
  studentNumber: string;
  gender: Gender;
  classroomDisplayName: string;
  fee_paid: number;
  status: StudentStatus;
  email: string;
  id: string;
  middleName: string;
  firstname: string;
  surname: string;
  term: string;
  _id: string;
}

export interface IUpdateStatusRequest {
  penalty: number;
  status: StudentStatus;
  minFee: number;
}
export interface IDeleteStudentsRequest {
  students: string[];
}

export enum FeeType {
  YEARLY_FEE = 'YEARLY_FEE',
  YEARLY_FEE_PRIMARY = 'YEARLY_FEE_PRIMARY',
  YEARLY_FEE_SECONDARY = 'YEARLY_FEE_SECONDARY',
  YEARLY_FEE_KINDERGARTEN = 'YEARLY_FEE_KINDERGARTEN',
  KIT_FEE_KINDERGARTEN = 'KIT_FEE_KINDERGARTEN',
  KIT_FEE_PRIMARY = 'KIT_FEE_PRIMARY',
  REGISTRATION = 'REGISTRATION',
  OTHER = 'OTHER',
  UNIFORM_PRIMARY = 'UNIFORM_PRIMARY',
  UNIFORM_KINDERGARTEN = 'UNIFORM_KINDERGARTEN',
  UNIFORM = 'UNIFORM',
  TRIP = 'TRIP',
  HIRE_CLASSROOM = 'HIRE_CLASSROOM',
  MEMBER_CONTRIBUTION = 'MEMBER_CONTRIBUTION',
  GYMNASTIC_OUTFIT_PRIMARY = 'GYMNASTIC_OUTFIT_PRIMARY',
  GYMNASTIC_OUTFIT_KINDERGARTEN = 'GYMNASTIC_OUTFIT_KINDERGARTEN',
  BOOKS_KINDERGARTEN = 'BOOKS_KINDERGARTEN',
  BOOKS_PRIMARY = 'BOOKS_PRIMARY',
  SPONSOR_FINANCE = 'SPONSOR_FINANCE',
}
export enum YearlyFeeType {
  YEARLY_FEE = 'YEARLY_FEE',
  YEARLY_FEE_PRIMARY = 'YEARLY_FEE_PRIMARY',
  YEARLY_FEE_KINDERGARTEN = 'YEARLY_FEE_KINDERGARTEN',
  YEARLY_FEE_SECONDARY = 'YEARLY_FEE_SECONDARY',
}
export interface PaymentStatus {
  feeType: FeeType;
  originalFeeType: FeeType;
  description?: string;
  credit: number;
  debit: number;
  lastPaymentDate: string;
}
export interface IStudent {
  firstname: string;
  middleName: string;
  surname: string;
  studentNumber: string;
  gender: Gender;
  email: string;
  classroom: string;
  classroomDisplayName: string;
  credit: number;
  debit: number;
  status: StudentStatus;
  year: string;
  paymentStatus: PaymentStatus[];
  school: string;
  term: string;
  message?: string;
  yearlySchoolFeeType: FeeType;
}
