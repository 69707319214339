import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginResponse } from '../../apis/types';
import { RootState } from '../index';
import { IUser } from '../../types/auth';

interface ILoginState {
  access_token?: string;
  user?: IUser;
  tenantId?: string;
  isLoggedIn: boolean;
  isInitialized: boolean;
}

const initialState: ILoginState = {
  isLoggedIn: false,
  isInitialized: false,
  user: undefined,
};

export const loginSlice = createSlice({
  name: 'login',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, { payload }: PayloadAction<ILoginResponse>) => {
      return {
        ...state,
        user: {
          username: payload.username,
          cellNumber: payload.cellNumber,
          firstName: payload.firstName,
          id: payload.id,
          lastName: payload.lastName,
          role: payload.role,
          permissions: payload.permissions,
        },
        isInitialized: true,
        isLoggedIn: true,
        tenantId: payload.tenantId,
      };
    },
    logOut: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        isInitialized: true,
      };
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.access_token = payload;
    },
  },
});

export const { setUser, logOut } = loginSlice.actions;
export const getLoggedInUser = (state: RootState) => state.login.user;
export const isUserLoggedIn = (state: RootState) => state.login.isLoggedIn;
export default loginSlice.reducer;
