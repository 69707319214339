import { createContext, ReactElement, useEffect } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { useDispatch, useSelector } from 'react-redux';
// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import { JWTContextType } from 'types/auth';
import {
  ACCESS_TOKEN,
  TENANT_ID,
  useLazyGetLoggedInUserQuery,
  useLoginMutation,
} from '../apis/konektApi';
import { logOut, setUser } from '../store/reducers/loginReducer';
import { RootState } from '../store';

const chance = new Chance();

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken: string, tenantId: string) => {
  if (serviceToken) {
    sessionStorage.setItem(ACCESS_TOKEN, serviceToken);
    sessionStorage.setItem(TENANT_ID, tenantId);
  }
};

const removeSession = () => {
  sessionStorage.removeItem(ACCESS_TOKEN);
  sessionStorage.removeItem(TENANT_ID);
};
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  // const [state, dispatch] = useReducer(authReducer, initialState);
  const dispatch = useDispatch();
  const [loginUser] = useLoginMutation();
  const [refreshLoggedInUser] = useLazyGetLoggedInUserQuery();
  const loginState = useSelector((state: RootState) => state.login);

  useEffect(() => {
    const init = async () => {
      try {
        const token = sessionStorage.getItem(ACCESS_TOKEN);
        if (token && verifyToken(token)) {
          const response = await refreshLoggedInUser().unwrap();
          dispatch(setUser(response));
        } else {
          dispatch(logOut());
        }
      } catch (err) {
        console.error(err);
        logout();
      }
    };

    init();
    // eslint-disable-next-line
  }, []);

  const login = async (email: string, password: string, companyName) => {
    const response = await loginUser({ username: email, companyName, password }).unwrap();
    const { access_token, tenantId } = response;
    dispatch(setUser(response));
    setSession(access_token, tenantId);
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      window.localStorage.getItem('users') !== undefined &&
      window.localStorage.getItem('users') !== null
    ) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    removeSession();
    dispatch(logOut());
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  if (loginState.isInitialized !== undefined && !loginState.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{ ...loginState, login, logout, register, resetPassword, updateProfile }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
