import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CashReportAnalytics from '../../sections/widget/chart/CashReportAnalytics';
import { useLazyGetCashReportForPeriodQuery } from '../../apis/konektApi';
import CircularLoader from '../../CircularLoader';
import React, { FC, useEffect } from 'react';
import { formatDate } from './stats-report';

interface IBalanceCashReportDetailsProps {
  dateFrom?: string;
  dateTo?: string;
}
const BalanceCashReportDetails: FC<IBalanceCashReportDetailsProps> = ({ dateFrom, dateTo }) => {
  const [getCashReport, cashReportResult] = useLazyGetCashReportForPeriodQuery();
  const { t } = useTranslation();
  const getDates = () => {
    if (cashReportResult.data && cashReportResult.data.length > 0) {
      return cashReportResult.data.map((item) => formatDate(item.date));
    }
    return [];
  };
  const getAmount = () => {
    if (cashReportResult.data && cashReportResult.data.length > 0) {
      return cashReportResult.data.map((item) => item.amount);
    }
    return [];
  };
  useEffect(() => {
    if (dateFrom && dateTo) {
      getCashReport({ dateFrom, dateTo });
    }
  }, [dateFrom, dateTo]);
  // useEffect(() => {
  //   if (dateFrom && dateTo) {
  //     getCashReport({ dateFrom, dateTo });
  //   }
  // }, [dateFrom, dateTo]);
  if (cashReportResult.isLoading) {
    return <CircularLoader />;
  } else if (cashReportResult.error || !cashReportResult.data) {
    return (
      <Box>
        <Typography>{t('errors.generic_error')}</Typography>
      </Box>
    );
  }
  return (
    <Box>
      <CashReportAnalytics />
    </Box>
  );
};

export default BalanceCashReportDetails;
