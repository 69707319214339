import { NavItemType } from '../types/menu';
import { Courthouse, DocumentUpload, UserSearch } from 'iconsax-react';

import i18n from '../i18n';
const icons = {
  upload: DocumentUpload,
  school: Courthouse,
  findStudent: UserSearch,
};
const studentsMenu: NavItemType = {
  id: 'students',
  title: i18n.t('nav_section.school'),
  type: 'group',
  children: [
    {
      id: 'findStudent',
      title: i18n.t('sidebar.find_student'),
      type: 'item',
      url: '/search',
      icon: icons.findStudent,
    },

    // {
    //   id: 'schools',
    //   title: i18n.t('sidebar.schools'),
    //   type: 'item',
    //   url: '/schools',
    //   icon: icons.school,
    // },
  ],
};
export default studentsMenu;
