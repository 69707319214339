import { IExpensesForCashResponse, IIncomesForCashResponse } from '../../apis/models/transactions';
import i18n from 'i18next';
import { enZA, fr } from 'date-fns/locale';
import { format } from 'date-fns';

export interface GroupedCashReportData {
  key: string;
  total: number;
  translatedKey?: string;
  percentage: number;
}

function calculateTotalForIncomes(incomes: IIncomesForCashResponse[]): number {
  return incomes.reduce((total, income) => total + income.fee_amount, 0);
}
function calculateTotalForExpenses(expenses: IExpensesForCashResponse[]): number {
  return expenses.reduce((total, expense) => total + expense.fee_amount, 0);
}

export function calculateTotalAndPercentageForIncomes(
  incomes: IIncomesForCashResponse[]
): GroupedCashReportData[] {
  const groupedData: { [key: string]: { total: number; count: number } } = {};

  incomes.forEach((income) => {
    const { feeType } = income;
    if (!groupedData[feeType]) {
      groupedData[feeType] = { total: 0, count: 0 };
    }
    groupedData[feeType].total += income.fee_amount;
    groupedData[feeType].count += 1;
  });

  return Object.keys(groupedData).map((key) => ({
    key,
    total: groupedData[key].total,
    percentage: Math.round((groupedData[key].total / calculateTotalForIncomes(incomes)) * 100),
  }));
}
export function calculateTotalAndPercentageForExpenses(
  expenses: IExpensesForCashResponse[]
): GroupedCashReportData[] {
  const groupedData: { [key: string]: { total: number; count: number } } = {};

  expenses.forEach((expense) => {
    const { feeCode } = expense;
    if (!groupedData[feeCode]) {
      groupedData[feeCode] = { total: 0, count: 0 };
    }
    groupedData[feeCode].total += expense.fee_amount;
    groupedData[feeCode].count += 1;
  });

  return Object.keys(groupedData).map((key) => ({
    key,
    total: groupedData[key].total,
    percentage: Math.round((groupedData[key].total / calculateTotalForExpenses(expenses)) * 100),
  }));
}
export function formatDate(date: string) {
  const locale = i18n.language.indexOf('fr') > -1 ? fr : enZA;
  return format(new Date(date), `EEE, d MMM yyyy`, {
    locale: locale,
  });
}
