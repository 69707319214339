import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  CurrencyEnum,
  IExchangeRateResponse,
  IFeeTypesResponse,
  IFeeTypesState,
  ILoginRequest,
  ILoginResponse,
  IResponseSummary,
  ISchools,
  IStudentSummary,
  ISuccessResponse,
  IUpdateFeeRequest,
  IUpdateStudentsStatus,
  UserRole,
} from './types';
import {
  IRegisterUserRequest,
  IRegisterUserResponse,
  IUsersAll,
  IUsersAllSummary,
} from './models/auth';
import {
  FeeType,
  IDeleteStudentsRequest,
  IStudent,
  IStudentsFetchAllByLevelRequest,
  IStudentsFetchAllRequest,
  IStudentTableView,
} from './models/students';
import { ICreateSchoolRequestDto, ICreateSchoolResponseDto } from './models/schools';
import {
  ICashReportSummary,
  IExpensesReport,
  IExpensesReportResponse,
  ITransactionForCashReportResponse,
  ITransactionForUserReport,
} from './models/transactions';
import dayjs from 'dayjs';
import { ISchoolTerm } from './models/schoolTerms';
import { RootState } from '../store';

import { KeyedObject } from '../types/root';
import jwtDecode from 'jwt-decode';
import { IPaymentHistory } from './models/fees';

export const ACCESS_TOKEN = 'access_token';
export const TENANT_ID = 'tenantId';

const TENANT_ID_KEY = 'x-tenant-id';
const USER_ROLE_HEADER_KEY = 'x-user-role';
const ACCESS_TOKEN_KEY = 'x-access-token';
const excludeUrls = ['login'];

export const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};
const getTokenFromStorage = () => {
  const token = sessionStorage.getItem(ACCESS_TOKEN);
  if (token && verifyToken(token)) {
    return token;
  }
  return null;
};
const getTenantIdFromStorage = () => {
  const tenantId = sessionStorage.getItem(TENANT_ID);
  if (tenantId) {
    return tenantId;
  }
  return null;
};

export const konektApi = createApi({
  reducerPath: 'konektApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const accessToken = (getState() as RootState).login.access_token || getTokenFromStorage();
      const tenantId = (getState() as RootState).login.tenantId || getTenantIdFromStorage();

      if (excludeUrls.includes(endpoint)) {
        return headers;
      }

      // If we have a token set in state, let's assume that we should be passing it.
      if (tenantId) {
        headers.set(TENANT_ID_KEY, tenantId);
      }
      if (accessToken) {
        headers.set(ACCESS_TOKEN_KEY, accessToken);
      }
      return headers;
    },
  }),
  tagTypes: ['Students', 'ExchangeRate', 'Login'],
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (body: ILoginRequest) => ({
        url: '/v1/auth/login',
        method: 'POST',
        headers: {
          [USER_ROLE_HEADER_KEY]: UserRole.ADMIN,
        },
        body,
      }),
      invalidatesTags: ['Students', 'ExchangeRate', 'Login'],
    }),
    getFeesMonthly: builder.query<IResponseSummary, { dateFrom: string }>({
      query: (queryArgs) => `/v1/reports/summary/${queryArgs.dateFrom}`,
      providesTags: ['Students'],
    }),
    getExchangeRate: builder.query<IExchangeRateResponse, void>({
      query: (queryArgs) => `/v1/fees/exchangeRate`,
    }),
    getLoggedInUser: builder.query<ILoginResponse, void>({
      query: (queryArgs) => `/v1/auth/loggedInUser`,
    }),
    updateExchangeRate: builder.mutation<
      IResponseSummary,
      { rate: number; currency: CurrencyEnum }
    >({
      query: (queryArgs) => {
        return {
          url: `/v1/fees/exchangeRate/${queryArgs.rate}/currency/${queryArgs.currency}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['ExchangeRate'],
    }),
    getAllUsers: builder.query<IUsersAllSummary[], void>({
      query: (queryArgs) => `/v1/auth/getAllUsers`,
      transformResponse(response: IUsersAll[]) {
        return response.map((user) => {
          return {
            ...user,
            id: user._id,
            names: `${user.lastName} ${user.firstName || ''}`,
          } as IUsersAllSummary;
        });
      },
    }),
    getClientFeeType: builder.query<IFeeTypesState, void>({
      query: (queryArgs) => `/v1/fees/getClientFeeType`,
      transformResponse(feeTypes: IFeeTypesResponse) {
        return {
          ...feeTypes,
          other: feeTypes.other.map((item) => item.sub_category || item).flat(),
        } as IFeeTypesState;
      },
    }),
    getUserById: builder.query<IUsersAllSummary, { id: string }>({
      query: (queryArgs) => `/v1/auth/getUserById/${queryArgs.id}`,
      transformResponse(user: IUsersAll) {
        return {
          ...user,
          id: user._id,
          names: `${user.lastName} ${user.firstName || ''}`,
        } as IUsersAllSummary;
      },
    }),
    getAllExpensesSummary: builder.query<IExpensesReport[], { dateFrom: string }>({
      query: (queryArgs) => `/v1/reports/expenses/${queryArgs.dateFrom}`,
      transformResponse(expenses: IExpensesReportResponse[]) {
        return expenses.map((item) => {
          return {
            ...item,
            id: item._id,
            cashier: item.cashier[0].firstName + ' ' + item.cashier[0].lastName,
            authorizer: item.authorizer[0].firstName + ' ' + item.authorizer[0].lastName,
            to: item.beneficiary_name,
            date: dayjs(item.date).format('MMM D, YYYY HH:mm'),
            description: item.description ? item.description : '',
          };
        });
      },
    }),
    getCashReportForPeriod: builder.query<
      ICashReportSummary[],
      { dateFrom: string; dateTo: string }
    >({
      query: (queryArgs) =>
        `/v1/reports/cashReportForPeriod/${queryArgs.dateFrom}/${queryArgs.dateTo}`,
    }),
    getTransactionForUser: builder.query<
      ITransactionForUserReport,
      { date: string; userId: string }
    >({
      query: (queryArgs) => `/v1/reports/transactions/${queryArgs.userId}/${queryArgs.date}`,
      transformResponse(transactionForUserReport: ITransactionForUserReport) {
        return {
          expenses: transactionForUserReport.expenses.map((expense) => {
            return {
              ...expense,
              id: expense._id,
              date: dayjs(expense.date).format('D MMM, HH:mm'),
            };
          }),
          incomes: transactionForUserReport.incomes.map((income) => {
            const classroomSplit = income.classroomDisplayName.split(' ');

            return {
              ...income,
              id: income._id,
              date_sold: dayjs(income.date_sold).format('D MMM, HH:mm'),
              classroom: classroomSplit[0] + ' ' + classroomSplit[1],
            };
          }),
        };
      },
    }),
    getTransactionsReportForPeriod: builder.query<
      ITransactionForCashReportResponse,
      { startDate: string; endDate: string }
    >({
      query: (queryArgs) =>
        `/v1/reports/balanceForPeriod/${queryArgs.startDate}/${queryArgs.endDate}`,
      transformResponse(transactionForCashReport: ITransactionForCashReportResponse) {
        return {
          expenses: transactionForCashReport.expenses.map((expense) => {
            return {
              ...expense,
              id: expense._id,
              date: dayjs(expense.date).format('D MMM, HH:mm'),
            };
          }),
          incomes: transactionForCashReport.incomes.map((income) => {
            return {
              ...income,
              id: income._id,
              date_sold: dayjs(income.date_sold).format('D MMM, HH:mm'),
            };
          }),
        };
      },
    }),
    getByStudentNumber: builder.query<IStudent, { studentNumber: string }>({
      query: (queryArgs) => `v1/schools/findStudent?studentNumber=${queryArgs.studentNumber}`,
      transformResponse(student: IStudent) {
        let credit = 0;
        student.paymentStatus = student.paymentStatus.map((item) => {
          if (item.feeType === student.yearlySchoolFeeType && item.feeType !== FeeType.YEARLY_FEE) {
            credit = item.credit;
            return {
              ...item,
              feeType: FeeType.YEARLY_FEE,
              originalFeeType: item.feeType,
            };
          }
          return { ...item, originalFeeType: item.feeType };
        });
        student.credit = credit;
        return student;
      },
    }),
    uploadStudents: builder.mutation<ISuccessResponse, FormData>({
      query: (formData: FormData) => {
        return {
          url: '/v1/students/uploadStudents',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Students'],
    }),
    updateStudentsStatus: builder.mutation<ISuccessResponse, IUpdateStudentsStatus>({
      query: (queryArgs) => {
        return {
          url: `/v1/students/setStatusByFee/?minFee=${queryArgs.minFee}&status=${queryArgs.status}&penalty=${queryArgs.penalty}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Students'],
    }),
    updateStudentDetails: builder.mutation<ISuccessResponse, Partial<IStudentSummary>>({
      query: (body) => {
        return {
          url: `/v1/students/details`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Students'],
    }),
    updateStudentFees: builder.mutation<ISuccessResponse, IUpdateFeeRequest>({
      query: (body) => {
        return {
          url: `/v1/students/fees`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Students'],
    }),
    deleteStudents: builder.mutation<ISuccessResponse, IDeleteStudentsRequest>({
      query: (body) => {
        return {
          url: `/v1/students`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['Students'],
    }),
    registerUser: builder.mutation<IRegisterUserResponse, IRegisterUserRequest>({
      query: (body: IRegisterUserRequest) => {
        return {
          url: '/v1/auth/register',
          method: 'POST',
          body: { ...body, branchCode: 'N/A' },
        };
      },
    }),
    addSchool: builder.mutation<ICreateSchoolResponseDto, ICreateSchoolRequestDto>({
      query: (body: ICreateSchoolRequestDto) => {
        return {
          url: '/v1/schools/create',
          method: 'POST',
          body: { ...body },
        };
      },
    }),
    addSchoolTerm: builder.mutation<ICreateSchoolResponseDto, ICreateSchoolRequestDto>({
      query: (body: ICreateSchoolRequestDto) => {
        return {
          url: '/v1/schools/create',
          method: 'POST',
          body: { ...body },
        };
      },
    }),
    getAllStudents: builder.query<IStudentTableView[], IStudentsFetchAllRequest>({
      query: (queryArgs) => {
        return `/v1/students/findAll/limit/${queryArgs.limit}/page/${queryArgs.page}`;
      },
      providesTags: ['Students'],
      transformResponse(response: IStudentSummary[]) {
        return response.map((std) => {
          return {
            ...std,
            fee_paid: std.credit,
            id: std.studentNumber,
            names: `${std.surname} ${std.middleName || ''} ${std.firstname || ''}`,
          } as IStudentTableView;
        });
      },
    }),
    getStudentPaymentHistory: builder.query<
      IPaymentHistory[],
      { studentNumber: string; studyYear: string }
    >({
      query: (queryArgs) => {
        return `/v1/fees/getPaymentHistory/${queryArgs.studentNumber}/${queryArgs.studyYear}`;
      },
      transformResponse(response: IPaymentHistory[]) {
        return response.map((payment) => {
          return {
            ...payment,
            id: payment._id,
          };
        });
      },
    }),
    getSchoolTerms: builder.query<ISchoolTerm[], void>({
      query: () => {
        return `/v1/schools/terms`;
      },
    }),
    getAllStudentsByLevel: builder.query<IStudentTableView[], IStudentsFetchAllByLevelRequest>({
      query: (queryArgs) => {
        return `/v1/students/findAllByLevel?level=${queryArgs.level}&pattern=${queryArgs.pattern}&term=${queryArgs?.term}`;
      },
      providesTags: ['Students'],
      transformResponse(response: IStudentSummary[]) {
        return response.map((std) => {
          return {
            ...std,
            fee_paid: std.credit,
            id: std.studentNumber,
            names: `${std.surname} ${std.middleName || ''} ${std.firstname || ''}`,
          } as IStudentTableView;
        });
      },
    }),
    reportStudents: builder.mutation<ISuccessResponse, FormData>({
      query: (formData: FormData) => {
        return {
          url: '/v1/students/uploadStudents',
          method: 'POST',
          body: formData,
        };
      },
    }),
    getAllSchools: builder.query<ISchools[], void>({
      query: () => `/v1/schools`,
    }),
  }),
});

export const {
  useGetUserByIdQuery,
  useGetFeesMonthlyQuery,
  useLazyGetFeesMonthlyQuery,
  useLoginMutation,
  useGetAllSchoolsQuery,
  useGetAllUsersQuery,
  useUploadStudentsMutation,
  useGetAllStudentsQuery,
  useRegisterUserMutation,
  useLazyGetByStudentNumberQuery,
  useGetByStudentNumberQuery,
  useUpdateStudentsStatusMutation,
  useAddSchoolMutation,
  useUpdateStudentFeesMutation,
  useUpdateStudentDetailsMutation,
  useDeleteStudentsMutation,
  useLazyGetAllStudentsByLevelQuery,
  useLazyGetAllExpensesSummaryQuery,
  useLazyGetTransactionForUserQuery,
  useLazyGetStudentPaymentHistoryQuery,
  useGetClientFeeTypeQuery,
  useLazyGetTransactionsReportForPeriodQuery,
  useLazyGetCashReportForPeriodQuery,
  useLazyGetExchangeRateQuery,
  useUpdateExchangeRateMutation,
  useGetSchoolTermsQuery,
  useLazyGetLoggedInUserQuery,
} = konektApi;
