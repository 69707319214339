import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStudentTableView } from '../../apis/models/students';
import { RootState } from '../index';

interface IStudentState {
  studentsList: IStudentTableView[];
}

const initialState: IStudentState = {
  studentsList: [],
};
export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setStudentsList: (state, { payload }: PayloadAction<IStudentTableView[]>) => {
      state.studentsList = payload;
    },
  },
});
export const { setStudentsList } = studentSlice.actions;
export const getStudentById = (state: RootState, stdNumber: string): IStudentTableView | null => {
  const studentList = state.student.studentsList;
  if (!studentList || !studentList.length) {
    return null;
  }
  return (studentList.find((std) => std.studentNumber === stdNumber) as IStudentTableView) || null;
};
export default studentSlice.reducer;
