import React, { memo, ReactElement, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITransactionForCashReportResponse } from '../../apis/models/transactions';
import { subDays } from 'date-fns';
import MainCard from '../../components/MainCard';

import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Grid from '@mui/material/Grid/Grid';
import { Box, Button, Tab, Tabs } from '@mui/material';
import IncomesCashReportDetails from './incomes-cash-report-details';
import ExpenseCashReportDetails from './expense-cash-report-details';

import {
  calculateTotalAndPercentageForExpenses,
  calculateTotalAndPercentageForIncomes,
  GroupedCashReportData,
} from './stats-report';
import BalanceCashReportDetails from './balance-cash-report-details';

interface ICashReportDetailsProps {
  getCashReportDetails: (dateFrom: string, dateEnd: string) => void;
  cashReportSummary?: ITransactionForCashReportResponse;
}

function getTabComponentByIndex(index: number) {
  return {
    id: `component-tab-${index}`,
    'aria-controls': `component-tabpanel-${index}`,
  };
}

const TabPanel = ({
  children,
  value,
  index,
}: {
  children?: ReactElement | ReactNode | string;
  value: number;
  index: number;
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};
export const CashReportPage: React.FC<ICashReportDetailsProps> = memo(
  ({ getCashReportDetails, cashReportSummary }) => {
    //
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
      dayjs(subDays(new Date(), 1)).startOf('day'),
      dayjs(subDays(new Date(), 1)).endOf('day'),
    ]);
    const [groupedIncomesStats, setGroupedIncomeStats] = useState<GroupedCashReportData[]>([]);
    const [groupedExpensesStats, setGroupedExpensesStats] = useState<GroupedCashReportData[]>([]);
    const [currentTab, setCurrentTab] = React.useState(0);
    const handleFromDateChange = (date: any) => {
      if (dateRange) {
        setDateRange(date);
      }
    };
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
      setCurrentTab(newValue);
    };
    const lookupForPeriod = () => {
      if (dateRange[0] && dateRange[1]) {
        getCashReportDetails(
          dateRange[0]?.startOf('day')?.toISOString(),
          dateRange[1]?.endOf('day').toISOString()
        );
      }
    };
    useEffect(() => {
      lookupForPeriod();
    }, []);
    useEffect(() => {
      if (cashReportSummary) {
        const groupedIncomeData = calculateTotalAndPercentageForIncomes(cashReportSummary?.incomes);
        setGroupedIncomeStats(groupedIncomeData);
      }
    }, [cashReportSummary]);
    useEffect(() => {
      if (cashReportSummary) {
        const groupedExpenseData = calculateTotalAndPercentageForExpenses(
          cashReportSummary?.expenses
        );
        setGroupedExpensesStats(groupedExpenseData);
      }
    }, [cashReportSummary]);
    const { t } = useTranslation();

    return (
      <MainCard title={t('titles.balance_details')}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={{ start: t('date_from'), end: t('date_to') }}
        >
          <Box>
            <Grid item xs={12} md={7} lg={8}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item md={6}></Grid>
                <Grid item md={4} pr={2}>
                  <Box>
                    <DateRangePicker
                      localeText={{ start: t('date_from'), end: t('date_to') }}
                      value={dateRange}
                      format={'DD/MM/YYYY'}
                      onChange={handleFromDateChange}
                    />
                  </Box>
                </Grid>
                <Grid item md={2}>
                  <Box>
                    <Button color="primary" variant="contained" onClick={lookupForPeriod}>
                      {t('search_btn')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box mt={2}>
                {cashReportSummary && (
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={12}>
                      <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs
                            value={currentTab}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                            sx={{ px: 3, pt: 1, '& .MuiTab-root': { mb: 0.5 } }}
                          >
                            <Tab
                              label={t('reports_breakdown.incomes')}
                              {...getTabComponentByIndex(0)}
                            />
                            <Tab
                              label={t('reports_breakdown.expenses')}
                              {...getTabComponentByIndex(1)}
                            />
                            <Tab
                              label={t('reports_breakdown.balance')}
                              {...getTabComponentByIndex(2)}
                            />
                          </Tabs>
                        </Box>
                        <Box sx={{ p: 3 }}>
                          <TabPanel value={currentTab} index={0}>
                            <>
                              <IncomesCashReportDetails
                                incomesTransactionResult={cashReportSummary.incomes}
                                groupedIncomesStats={groupedIncomesStats}
                              />
                            </>
                          </TabPanel>
                          <TabPanel value={currentTab} index={1}>
                            <ExpenseCashReportDetails
                              expenseTransactionResult={cashReportSummary.expenses}
                              groupedExpensesStats={groupedExpensesStats}
                            />
                          </TabPanel>
                          <TabPanel value={currentTab} index={2}>
                            <BalanceCashReportDetails
                              dateTo={dateRange[0]?.startOf('day')?.toISOString()}
                              dateFrom={dateRange[1]?.startOf('day')?.toISOString()}
                            />
                          </TabPanel>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Box>
        </LocalizationProvider>
      </MainCard>
    );
  }
);

export default CashReportPage;
