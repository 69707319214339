// third-party
import { combineReducers } from 'redux';

// project-imports
import menu from './menu';
import snackbar from './snackbar';
import loginReducer from './loginReducer';
import studentReducer from './studentReducer';
import feeTypesReducer from './feeTypesReducer';
import { konektApi } from '../../apis/konektApi';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  login: loginReducer,
  student: studentReducer,
  feeTypes: feeTypesReducer,
  [konektApi.reducerPath]: konektApi.reducer,
});

export default reducers;
