import { useGetAllUsersQuery } from '../../apis/konektApi';
import { useTranslation } from 'react-i18next';
import CircularLoader from '../../CircularLoader';
import { useNavigate } from 'react-router-dom';
import { AntDesignStyledDataGridPro } from '../../components/AntDesignStyle';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import MainCard from '../../components/MainCard';
import { ThemeMode } from '../../types/config';
import { useTheme } from '@mui/material/styles';
import { Edit, Eye } from 'iconsax-react';

const UserListPage = () => {
  const theme = useTheme();
  const { data, error, isLoading } = useGetAllUsersQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isLoading) return <CircularLoader />;
  const DataGridComponent = AntDesignStyledDataGridPro;
  const renderDetailsButton = (params) => {
    const mode = theme.palette.mode;
    return (
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor:
                  mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                opacity: 0.9,
              },
            },
          }}
          title="View"
        >
          <IconButton color="secondary" onClick={() => navigate(`/users/${params?.id}`)}>
            <Eye />
          </IconButton>
        </Tooltip>
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor:
                  mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                opacity: 0.9,
              },
            },
          }}
          title="Edit"
        >
          <IconButton color="primary" onClick={() => {}}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Stack>
      // <strong>
      //   <Button
      //     variant="contained"
      //     color="primary"
      //     size="small"
      //     style={{ marginLeft: 16 }}
      //     onClick={() => {
      //       navigate(`/users/${params?.id}`);
      //     }}
      //   >
      //     {t('more_info')}
      //   </Button>
      // </strong>
    );
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Box sx={{ flex: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };
  const userListColumn = [
    { field: 'names', headerName: t('tables.names'), width: 210, sortable: false },
    { field: 'cellNumber', headerName: t('tables.cell_number'), width: 150, sortable: false },
    { field: 'email', headerName: t('tables.email'), width: 250, sortable: false },
    { field: 'role', headerName: t('tables.role'), width: 100, sortable: false },
    {
      field: 'details',
      headerName: 'Details',
      width: 150,
      sortable: false,
      renderCell: renderDetailsButton,
    },
  ];
  if (!data || !data.length || error) {
    return <Box>{t('errors.users_not_found')}</Box>;
  } else {
    return (
      <MainCard title={t('titles.user_management')}>
        <Box sx={{ height: '600px' }}>
          <DataGridComponent
            pagination
            pageSize={20}
            rowsPerPageOptions={[20]}
            columns={[...userListColumn]}
            rows={[...data]}
            {...userListColumn}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            loading={isLoading}
            checkboxSelection
            disableSelectionOnClick
            rowThreshold={0}
            initialState={{}}
          />
          {/*<Dialog*/}
          {/*  maxWidth="sm"*/}
          {/*  TransitionComponent={PopupTransition}*/}
          {/*  keepMounted*/}
          {/*  fullWidth*/}
          {/*  onClose={handleAdd}*/}
          {/*  open={add}*/}
          {/*  sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}*/}
          {/*  aria-describedby="alert-dialog-slide-description"*/}
          {/*>*/}
          {/*  /!*<AddCustomer customer={customer} onCancel={handleAdd} />*!/*/}
          {/*</Dialog>*/}
        </Box>
      </MainCard>
    );
  }
};
export default UserListPage;
