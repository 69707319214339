import { CircularProgress, Grid } from '@mui/material';
import Loader from './components/Loader';

const CircularLoader = () => {
  return (
    <>
      <Loader />
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress size={80} />;
      </Grid>
    </>
  );
};
export default CircularLoader;
