import { MouseEvent, useState } from 'react';

// material-ui
import { ListItemButton, Menu, Stack, Typography } from '@mui/material';

// project-imports
import MonthlyReportChart from './MonthlyReportChart';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';

// assets
import { More } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { ICashReportBalanceSummary } from '../../../apis/types';

interface ICashReportBalanceProps {
  cashReportSummary: ICashReportBalanceSummary[];
}

const CashReportBalance: React.FC<ICashReportBalanceProps> = ({ cashReportSummary }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const [monthlyDataReversed] = useState(cashReportSummary.slice().reverse());

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MainCard>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <Typography variant="h5">{t('dashboard.earning_report_period')}</Typography>
        <IconButton
          color="secondary"
          id="wallet-button"
          aria-controls={open ? 'wallet-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <More />
        </IconButton>
        <Menu
          id="wallet-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'wallet-button',
            sx: { p: 1.25, minWidth: 150 },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ListItemButton onClick={handleClose}>Weekly</ListItemButton>
          <ListItemButton onClick={handleClose}>Monthly</ListItemButton>
        </Menu>
      </Stack>
      <MonthlyReportChart monthlyReport={monthlyDataReversed} />
    </MainCard>
  );
};

export default CashReportBalance;
