import { FC, MouseEvent, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography } from '@mui/material';

// third-party
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';

// project-imports
import MainCard from 'components/MainCard';

// assets

// types
import { ThemeMode } from 'types/config';
import { GroupedCashReportData } from '../pages/cash-report/stats-report';
import { useTranslation } from 'react-i18next';

// ==============================|| CHART ||============================== //
interface IGroupedData {
  data: GroupedCashReportData[];
}

const ApexBarChart: FC<{ seriesInput: number[]; labels: string[] }> = ({ seriesInput, labels }) => {
  const theme = useTheme();
  const downSM = theme.breakpoints.down('sm');
  const mode = theme.palette.mode;
  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const backColor = theme.palette.background.paper;

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: downSM ? 180 : 250,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: [primary, primary, primary, primary, primary, primary, primary],
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [primary],
        },
      },
    },
    colors: [theme.palette.primary.main], // Only one color
    grid: {
      borderColor: line,
    },
    stroke: {
      colors: [backColor],
    },
    theme: {
      mode: mode === ThemeMode.DARK ? 'dark' : 'light',
    },
  };

  const [series] = useState([{ data: seriesInput }]);
  const [options, setOptions] = useState<ChartProps>(barChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main],
    }));
  }, [theme, seriesInput]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={downSM ? 280 : 350} />
    </div>
  );
};

const AccountBreakdown: FC<IGroupedData> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MainCard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <Typography variant="h5">{t('account_breakdown')}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ApexBarChart
            seriesInput={data.map((it) => it.percentage)}
            labels={data.map((it) => it.translatedKey || it.key)}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default AccountBreakdown;
