import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import React, { useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IIncomesTransaction } from '../../apis/models/transactions';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import DownloadIcon from '@mui/icons-material/Download';

interface ITransactionHistoryProps {
  incomesTransactionResult: IIncomesTransaction[];
  employee_name: string;
}

const IncomeTransactionHistory: React.FC<ITransactionHistoryProps> = ({
  incomesTransactionResult,
  employee_name,
}) => {
  const { t } = useTranslation();

  const downloadReport = () => {
    const report = new jsPDF();
    autoTable(report, {
      head: [
        [
          t('tables.receipt_number'),
          t('tables.expense_code'),
          t('tables.names'),
          t('tables.amount'),
          t('tables.classroom_display_name'),
        ],
      ],
      body: incomesTransactionResult.map((item) => {
        return [item.receiptNumber, item.feeType, item.from, item.fee_amount, item.classroom];
      }),
    });
    report.save(`${employee_name}_${t('dashboard.earning_label')}.pdf`.toLowerCase());
  };
  const columns = useMemo(
    () => [
      {
        header: t('tables.date'),
        accessorKey: 'date_sold',
        enableGrouping: false, //do not let this column be grouped
      },
      {
        header: t('tables.expense_code'),
        accessorKey: 'feeType',
      },
      {
        header: t('tables.names'),
        accessorKey: 'from',
      },
      {
        header: t('tables.amount'),
        accessorKey: 'fee_amount',

        aggregationFn: 'sum',
        AggregatedCell: ({ cell, table }) => {
          const colorValue = cell.getValue() > 0 ? 'success.main' : 'error.main';
          return (
            <>
              {t('tables.sum_by') +
                ' ' +
                table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}
              :{' '}
              <Box sx={{ color: colorValue, fontWeight: 'bold' }}>
                {cell.getValue()?.toLocaleString?.('fr-FR', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Box>
            </>
          );
        },
      },
      {
        header: t('tables.classroom_display_name'),
        accessorKey: 'classroom',
      },
    ],
    [incomesTransactionResult, t]
  );
  return (
    <MaterialReactTable
      // @ts-ignore
      columns={columns}
      data={incomesTransactionResult}
      renderToolbarInternalActions={({ table }) => (
        <>
          <MRT_ToggleGlobalFilterButton table={table} />
          <IconButton onClick={downloadReport}>
            <DownloadIcon />
          </IconButton>
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_FullScreenToggleButton table={table} />
        </>
      )}
      enableColumnResizing
      enableGrouping
      enableStickyHeader
      enableStickyFooter
      initialState={{
        density: 'compact',
        expanded: true, //expand all groups by default
        pagination: { pageIndex: 0, pageSize: 15 },
        sorting: [{ id: 'feeType', desc: false }], //sort by state by default
      }}
      muiToolbarAlertBannerChipProps={{ color: 'primary' }}
      muiTableContainerProps={{ sx: { maxHeight: 700 } }}
    />
  );
};
export default IncomeTransactionHistory;
