import CashReportPage from './cash-report-page';
import { useLazyGetTransactionsReportForPeriodQuery } from '../../apis/konektApi';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularLoader from '../../CircularLoader';

export const CashReportPageContainer = () => {
  const { t } = useTranslation();

  const [getCashReport, cashReportResult] = useLazyGetTransactionsReportForPeriodQuery();
  const onDateUpdated = (startDate: string, endDate: string) => {
    getCashReport({ startDate, endDate });
  };
  if (cashReportResult.isLoading) {
    return <CircularLoader />;
  } else if (cashReportResult.error) {
    return (
      <Box>
        <Typography>{t('errors.generic_error')}</Typography>
      </Box>
    );
  }
  return (
    <CashReportPage
      getCashReportDetails={onDateUpdated}
      cashReportSummary={cashReportResult.data}
    />
  );
};
export default CashReportPageContainer;
