import { useRegisterUserMutation } from '../../apis/konektApi';
import { IRegisterUserRequest } from '../../apis/models/auth';
import { toast } from 'react-toastify';
import React from 'react';
import Loader from '../../components/Loader';
import RegisterUserPage from './RegisterUserPage';

const RegisterUserPageContainer = () => {
  const [register, { isLoading, error }] = useRegisterUserMutation();
  const registerUser = async (userRequest: IRegisterUserRequest) => {
    try {
      const response = await register(userRequest).unwrap();
      const message = `Username : ${response.username} Password: ${response.secret}`;
      toast.success(`User registered successfully, ${message}`);
    } catch (e) {
      toast.error(`failed to register user ${JSON.stringify(e)}`);
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return <RegisterUserPage registerUser={registerUser} error={error} />;
};
export default RegisterUserPageContainer;
