// third-party
// assets
import { Profile2User, UserSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';
import i18n from '../i18n';
// icons
const icons = {
  profile: UserSquare,
  users: Profile2User,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const supportMenu: NavItemType = {
  id: 'support',
  title: i18n.t('nav_section.users'),
  type: 'group',
  children: [
    {
      id: 'userManagement',
      title: i18n.t('sidebar.user_management'),
      type: 'item',
      url: '/users',
      icon: icons.users,
    },
    {
      id: 'registerUser',
      title: i18n.t('sidebar.register_user'),
      type: 'item',
      url: '/registerUser',
      icon: icons.profile,
    },
  ],
};

export default supportMenu;
