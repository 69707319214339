// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Stack, useMediaQuery } from '@mui/material';

import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// type
import { MenuOrientation } from 'types/config';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Box sx={{ width: '100%', ml: { xs: 0, md: 2 } }} />}
      {/*{!downLG && localization}*/}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <Stack direction="row" justifyContent="end">
        {/*<Notification />*/}
        {!downLG && <Profile />}
      </Stack>

      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
