import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IIncomesForCashResponse } from '../../apis/models/transactions';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { GroupedCashReportData } from './stats-report';
import AccountBreakdown from '../../components/AccountBreakdown';

interface ICashReportHistoryProps {
  incomesTransactionResult: IIncomesForCashResponse[];
  groupedIncomesStats?: GroupedCashReportData[];
}

const IncomesCashReportDetails: React.FC<ICashReportHistoryProps> = ({
  incomesTransactionResult,
  groupedIncomesStats,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [groupedIncomes, setGroupedIncomes] = useState<GroupedCashReportData[] | undefined>();
  const incomeFeeTypes = useSelector((state: RootState) => {
    return state.feeTypes.other.concat(state.feeTypes.student);
  });
  useEffect(() => {
    if (groupedIncomesStats && groupedIncomesStats.length > 0) {
      const groupedIncomeData = groupedIncomesStats.map((item) => {
        const translatedValue = incomeFeeTypes.find((feeType) => {
          return feeType && feeType.enum_key && feeType.enum_key === item.key;
        });
        return {
          ...item,
          translatedKey:
            currentLanguage.indexOf('fr') >= 0
              ? translatedValue?.value_fr || 'Compte non trouvé'
              : translatedValue?.value_en || 'Account not found',
        };
      });
      setGroupedIncomes(groupedIncomeData);
    }
  }, [groupedIncomesStats, incomeFeeTypes]);
  const transactionsResult = useMemo(() => {
    return incomesTransactionResult.map((row) => {
      const translatedValue = incomeFeeTypes.find((item) => {
        return item && item.enum_key && item.enum_key === row.feeType;
      });
      return {
        ...row,
        feeType:
          currentLanguage.indexOf('fr') > 0
            ? translatedValue?.value_fr || 'Compte non trouvé'
            : translatedValue?.value_en || 'Account not found',
      };
    });
  }, [incomeFeeTypes, incomesTransactionResult]);

  const downloadReport = (table) => {
    const globalFilter = table.getState().globalFilter;
    const filteredTransactions = globalFilter
      ? transactionsResult.filter((transaction) =>
          transaction.feeType.toLowerCase().includes(globalFilter.toLowerCase())
        )
      : transactionsResult;
    const groupedTransactions = filteredTransactions.reduce((grouped, item) => {
      const feeType = item.feeType;

      if (!grouped.has(feeType)) {
        grouped.set(feeType, { items: [], total: 0 });
      }

      grouped.get(feeType).items.push(item);
      grouped.get(feeType).total += item.fee_amount;
      return grouped;
    }, new Map());

    const report = new jsPDF();
    let grandTotal = 0;

    // Iterate through the grouped transactions and generate tables for each feeType
    groupedTransactions.forEach(({ items, total }, feeType) => {
      grandTotal += total;

      report.text(`${t('tables.expense_code')} : ${feeType}`, 10, 10);

      autoTable(report, {
        head: [
          [
            t('tables.receipt_number'),
            t('tables.expense_code'),
            t('tables.names'),
            t('tables.amount'),
            t('tables.classroom_display_name'),
          ],
        ],
        body: items.map((item) => [
          item.date_sold,
          item.feeType,
          item.names,
          item.fee_amount,
          item.cashier,
          item.description,
        ]),
        didDrawPage: (data) => {
          // Add total amount for each feeType at the bottom
          report.text(
            `${t('tables.total_amount_category')} : ${total.toFixed(2)}$`,
            10,
            data && data.cursor ? data.cursor.y + 10 : 0
          );
        },
      });

      report.addPage(); // Add a new page for each feeType
    });

    // Add grand total at the end of the document
    report.text(`${t('tables.grand_total')} : ${grandTotal.toFixed(2)}$`, 10, 10);

    report.save(`${t('dashboard.earning_label')}.pdf`.toLowerCase());
  };
  const columns = useMemo(
    () => [
      {
        header: t('tables.expense_code'),
        accessorKey: 'feeType',
      },
      {
        header: t('tables.date'),
        accessorKey: 'groupId',
      },
      {
        header: t('tables.amount'),
        accessorKey: 'fee_amount',

        aggregationFn: 'sum',
        AggregatedCell: ({ cell, table }) => {
          const colorValue = cell.getValue() > 0 ? 'success.main' : 'error.main';
          return (
            <>
              {t('tables.sum_by') +
                ' ' +
                table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}
              :{' '}
              <Box sx={{ color: colorValue, fontWeight: 'bold' }}>
                {cell.getValue()?.toLocaleString?.('fr-FR', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Box>
            </>
          );
        },
      },
      {
        header: t('tables.names'),
        accessorKey: 'names',
      },
    ],
    [transactionsResult, t]
  );

  const total = useMemo(() => {
    const total = transactionsResult.reduce((a, b) => a + b.fee_amount, 0);
    return total.toFixed(2);
  }, [transactionsResult]);
  return (
    <>
      <Typography mb={1} variant="h3">
        {t('income_lbl') + total + '$'}
      </Typography>
      <MaterialReactTable
        // @ts-ignore
        columns={columns}
        data={transactionsResult}
        renderToolbarInternalActions={({ table }) => (
          <>
            <MRT_ToggleGlobalFilterButton table={table} />
            <IconButton onClick={() => downloadReport(table)}>
              <DownloadIcon />
            </IconButton>
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_FullScreenToggleButton table={table} />
          </>
        )}
        enableColumnResizing
        enableGrouping
        enableStickyHeader
        enableStickyFooter
        initialState={{
          density: 'compact',
          expanded: true, //expand all groups by default
          grouping: ['feeType'], //an array of columns to group by default (can be multiple)
          pagination: { pageIndex: 0, pageSize: 15 },
          sorting: [{ id: 'groupId', desc: false }], //sort by state by default
        }}
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        muiTableContainerProps={{ sx: { maxHeight: 700 } }}
      />
      <Box mt={1}>
        {groupedIncomes && groupedIncomes.length > 0 && <AccountBreakdown data={groupedIncomes} />}
      </Box>
    </>
  );
};
export default IncomesCashReportDetails;
