import { IRegisterUserRequest } from '../../apis/models/auth';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import MainCard from '../../components/MainCard';
import { Box, Button, Grid, TextField } from '@mui/material';

interface IRegisterUserPageProps {
  registerUser: (userRequest: IRegisterUserRequest) => void;
  error?: any;
  registerSuccess?: boolean;
}
const RegisterUserPage: FC<IRegisterUserPageProps> = ({ registerSuccess, registerUser }) => {
  const initialValues: IRegisterUserRequest = {
    email: '',
    firstName: '',
    lastName: '',
    cellNumber: '',
  };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    onSubmit: (form) => {
      registerUser(form);
    },
  });
  return (
    <MainCard title={t('titles.register_user')} sx={{ width: '60%' }}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              label={t('email_label')}
              name="email"
              onChange={formik.handleChange}
              required
              type="text"
              value={formik.values.email}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={Boolean(formik.touched.cellNumber && formik.errors.cellNumber)}
              fullWidth
              label={t('cellNumber_label')}
              name="cellNumber"
              onChange={formik.handleChange}
              required
              type="text"
              value={formik.values.cellNumber}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={Boolean(formik.touched.firstName && formik.errors.firstName)}
              fullWidth
              label={t('firstname_label')}
              name="firstName"
              onChange={formik.handleChange}
              required
              type="text"
              value={formik.values.firstName}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              fullWidth
              label={t('surname_label')}
              name="lastName"
              onChange={formik.handleChange}
              required
              type="text"
              value={formik.values.lastName}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 8,
            display: 'flex',
            justifyContent: 'space-evenly',
            p: 2,
          }}
        >
          <Button color={'primary'} variant="outlined" onClick={() => formik.resetForm()}>
            {t('cancel_btn')}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {t('submit_btn')}
          </Button>
        </Box>
      </form>
    </MainCard>
  );
};
export default RegisterUserPage;
