import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DashboardDefault from '../pages/dashboard/DashboardPageContainer';
import CashReportPageContainer from '../pages/cash-report/cash-report-page-container';
import UserListPage from '../pages/user-management/UserListPage';
import UserDetailsPage from '../pages/user-management/UserDetailsPage';
import RegisterUserPageContainer from '../pages/user-registration/RegisterUserPageContainer';

// const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
// const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const StudentProfilePageContainer = Loadable(
  lazy(() => import('../pages/student-profile/StudentProfilePageContainer'))
);
const StudentListPageContainer = Loadable(
  lazy(() => import('../pages/students/StudentListPageContainer'))
);
const CurrencyExchangePage = Loadable(
  lazy(() => import('../pages/currency-exchange/currency-exchange-page'))
);

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: '',
              element: <DashboardDefault />,
            },
            {
              path: 'transactions',
              element: <CashReportPageContainer />,
            },
          ],
        },
        {
          path: 'registerUser',
          element: <RegisterUserPageContainer />,
        },
        {
          path: 'users',
          children: [
            {
              path: '',
              element: <UserListPage />,
            },
            {
              path: ':id',
              element: <UserDetailsPage />,
            },
          ],
        },
        {
          path: 'search',
          children: [
            {
              path: '',
              element: <StudentListPageContainer />,
            },
            {
              path: ':id',
              element: <StudentProfilePageContainer />,
            },
          ],
        },
        {
          path: 'uploadStudents',
          element: <SamplePage />,
        },
        {
          path: 'userManagement',
          element: <SamplePage />,
        },
        {
          path: 'exchangeRate',
          element: <CurrencyExchangePage />,
        },
      ],
    },
  ],
};

export default MainRoutes;
