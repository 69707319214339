import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeeTypeItem } from '../../apis/types';
import { RootState } from '../index';
import { konektApi } from '../../apis/konektApi';

export interface IUser {
  firstName: string;
  lastName: string;
  username: string;
  id: string;
  cellNumber: string;
}

interface IFeeTypesState {
  other: FeeTypeItem[];
  student: FeeTypeItem[];
  expenses: FeeTypeItem[];
}

const initialState: IFeeTypesState = {
  other: [],
  student: [],
  expenses: [],
};
const FEE_TYPES_KEY = 'feeTypes';
const loadFeeTypesFromSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem(FEE_TYPES_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
const saveItemsToSessionStorage = (items) => {
  try {
    const serializedState = JSON.stringify(items);
    sessionStorage.setItem(FEE_TYPES_KEY, serializedState);
  } catch (err) {
    // Handle any errors when saving to local storage
  }
};

const persistedState = loadFeeTypesFromSessionStorage();
const initialStateWithFallback: IFeeTypesState = persistedState ? persistedState : initialState;
export const feeTypesSlice = createSlice({
  name: 'feeTypes',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: initialStateWithFallback,
  reducers: {
    setFeeTypes: (state, { payload }: PayloadAction<IFeeTypesState>) => {
      const serializedState = JSON.stringify(state);
      sessionStorage.setItem(FEE_TYPES_KEY, serializedState);
    },
    getFeeTypes: (state): IFeeTypesState => {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(konektApi.endpoints.getClientFeeType.matchFulfilled, (state, action) => {
      const items = action.payload;
      saveItemsToSessionStorage(items);
      return action.payload; // Update the state with the fetched items
    });
  },
});

export const { setFeeTypes, getFeeTypes } = feeTypesSlice.actions;
export const getIncomesFeeTypes = (state: RootState) =>
  state.feeTypes.other.concat(state.feeTypes.student);
export const getExpensesFeeTypes = (state: RootState) => state.feeTypes.expenses;
export default feeTypesSlice.reducer;
