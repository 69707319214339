// material-ui
import { Box, Button, CardContent, Grid, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';

// assets
import { useTranslation } from 'react-i18next';
import { ICashReportBalanceSummary } from '../../../apis/types';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { ColorProps } from '../../../types/extended';
import { formatDate } from '../../../pages/cash-report/stats-report';

interface ITaskCardProps {
  weeklyEarnings: ICashReportBalanceSummary[];
}

// ==============================|| DATA WIDGET - TASKS ||============================== //

const WeeklyBalance: FC<ITaskCardProps> = ({ weeklyEarnings }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const earningsColor: ColorProps[] = ['success', 'primary', 'warning', 'error', 'info'];

  return (
    <MainCard title={t('last_x_days')} content={false}>
      <CardContent>
        <Grid
          container
          spacing={3.5}
          alignItems="center"
          sx={{
            position: 'relative',
            '&>*': {
              position: 'relative',
              zIndex: '5',
            },
            '&:after': {
              content: '""',
              position: 'absolute',
              top: 30,
              left: 34,
              width: 2,
              height: `calc(100% - 30px)`,
              bgcolor: 'divider',
              zIndex: '1',
            },
          }}
        >
          {weeklyEarnings.map((earning, index) => (
            <Grid key={index} item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Box sx={{ marginLeft: -0.5 }}>
                    <Dot size={14} color={earningsColor[index]} componentDiv sx={{ mt: 0.5 }} />
                  </Box>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography align="left">{formatDate(earning.date)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align="left" variant="h5" color="text.secondary">
                        {earning.amount.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                navigate('/dashboard/transactions');
              }}
            >
              {t('view_transactions_btn')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </MainCard>
  );
};

export default WeeklyBalance;
