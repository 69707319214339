// material-ui
import { Button, Link, CardMedia, Stack, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';

// assets
import avatar from 'assets/images/users/customer-support-1.png';
import AnimateButton from 'components/@extended/AnimateButton';
import { useTranslation } from 'react-i18next';

// ==============================|| DRAWER CONTENT - NAV CARD ||============================== //

const NavCard = () => {
  const { t } = useTranslation();
  return (
    <MainCard sx={{ bgcolor: 'secondary.lighter', m: 3 }}>
      <Stack alignItems="center" spacing={2.5}>
        <CardMedia component="img" image={avatar} />
        <Stack alignItems="center">
          <Typography variant="h5">{t('support_lbl')}</Typography>
        </Stack>
        <AnimateButton>
          <Button
            variant="shadow"
            size="small"
            component={Link}
            href="https://phoenixcoded.authordesk.app/"
            target="_blank"
          >
            {t('support_btn')}
          </Button>
        </AnimateButton>
      </Stack>
    </MainCard>
  );
};

export default NavCard;
